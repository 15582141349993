import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import ReactMapboxGl, {Feature, Layer, MapContext, Marker, Source} from 'react-mapbox-gl';
import {LngLat} from 'mapbox-gl';
import center from "@turf/center";
import distance from "@turf/distance";
import {IconButton} from 'components/IconButton/IconButton';
import {ParticipantMarker} from 'components/ParticipantMarker/ParticipantMarker';
import {Context, STOP_FOLLOW_PARTICIPANTS, TOGGLE_FOLLOW_PARTICIPANTS} from 'context/Context';
import {isDark} from 'helpers/colorHelper';
import checkpointSvg from 'assets/svg/checkpoint.svg';
import endpointSvg from 'assets/svg/endpoint.svg';
import changeMapView from 'assets/svg/icon_earth.svg';
import changeMapViewSatellite from 'assets/svg/icon_earth_satellite.svg';
import fitBoundsSvg from 'assets/svg/icon_bounds.svg';
import followParticipantsSvg from 'assets/svg/followParticipants.svg';
import followParticipantsActiveSvg from 'assets/svg/followParticipants-active.svg';
import elevationGraphSvg from 'assets/svg/elevationGraph.svg';
import elevationGraphActiveSvg from 'assets/svg/elevationGraph-active.svg';
import './Map.scss';

const linePaint = (color) => ({
    'line-color': color,
    'line-width': 4,
});

const lineLayout = {
    'line-join': 'round',
    'line-cap': 'round',
};

const Mapbox = ReactMapboxGl({
    accessToken: `${process.env.REACT_APP_MAPBOX_PUBLIC_KEY}`,
});

const defaultMapProps = {
    fitBounds: getBounds(24.776920124141384, 45.99431035005913, 100000),
};

export function Map({height, fitBoundsOptions, onElevationGraphIconClick, isElevationGraphDisplayed, showScale, overlay, hasElevationGraph}) {
    const {
        state: {activeRace, activeTrail, participantPoints, selectedParticipants, followParticipants},
        dispatch,
    } = useContext(Context);

    const [mapProps, setMapProps] = useState(() => buildMapProps(activeTrail, activeRace, fitBoundsOptions));
    const [toggleSatelliteStyle, setToggleSatelliteStyle] = useState(false);

    const fitToTrailBounds = useCallback(() => {
        dispatch({type: STOP_FOLLOW_PARTICIPANTS});
        setMapProps(() => ({
            fitBounds: getTrailBounds(activeTrail),
            fitBoundsOptions,
        }));
    }, [activeTrail, dispatch, fitBoundsOptions]);

    useEffect(() => {
        const overlayKeysLength = Object.keys(overlay).length;
        const overlayFilename = overlayKeysLength ? overlay.overlayFilename : null;
        const overlayUrl = overlayKeysLength ? overlay.overlayUrl : null;
        const overlayCoordinates = overlayKeysLength ? JSON.parse(overlay.overlayCoordinates) : null;

        if (!overlayFilename || !overlayUrl || overlayCoordinates.length !== 4) {
            return;
        }

        const {geometry: overlayCenter} = center(
            {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "Polygon",
                            coordinates: [overlayCoordinates]
                        }
                    }
                ]
            });
        const overlayWidth = distance(overlayCoordinates[0], overlayCoordinates[1], {units: "meters"});
        setMapProps(() => ({
            fitBounds: getBounds(overlayCenter.coordinates[0], overlayCenter.coordinates[1], overlayWidth * 0.3),
            fitBoundsOptions,
        }));
    }, [fitBoundsOptions, overlay]);

    useEffect(() => {
        setMapProps(() => buildMapProps(activeTrail, activeRace, fitBoundsOptions));
    }, [activeTrail, activeRace, fitToTrailBounds, fitBoundsOptions]);

    useEffect(() => {
        if (followParticipants && selectedParticipants.length > 0) {
            if (selectedParticipants.length === 1) {
                const participant = findSelectedParticipant(participantPoints, selectedParticipants[0]);
                if (participant && participant.points?.length > 0) {
                    const points = participant.points;

                    setMapProps(() => ({
                        fitBounds: getBounds(points[points.length - 1].long, points[points.length - 1].lat, 50),
                        fitBoundsOptions,
                    }));
                }
            } else {
                setMapProps(() => ({
                    fitBounds: getSelectedParticipantsBounds(participantPoints, selectedParticipants),
                    fitBoundsOptions,
                }));
            }
        }
    }, [followParticipants, participantPoints, selectedParticipants, fitBoundsOptions]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMapProps((p) => ({
                ...p,
                fitBounds: [...p.fitBounds],
                fitBoundsOptions: {...p.fitBoundsOptions},
            }));
        }, 20);

        return () => clearTimeout(timeoutId);
    }, [height]);

    const onChangeMapStyle = () => {
        setToggleSatelliteStyle(!toggleSatelliteStyle);
    };

    return (
        <Mapbox
            className='map'
            {...mapProps}
            // eslint-disable-next-line react/style-prop-object
            style={toggleSatelliteStyle ? process.env.REACT_APP_MAPBOX_SATELLITE_STYLE : process.env.REACT_APP_MAPBOX_STYLE}
            containerStyle={{
                height,
            }}
            renderChildrenInPortal
        >
            <MapContext.Consumer>
                {(map) => {
                    const overlayKeysLength = Object.keys(overlay).length;
                    const gpxTileset = activeTrail ? activeTrail.gpxTilesetName : null;
                    const overlayFilename = overlayKeysLength ? overlay.overlayFilename : null;
                    const overlayUrl = overlayKeysLength ? overlay.overlayUrl : null;
                    const overlayCoordinates = overlayKeysLength ? JSON.parse(overlay.overlayCoordinates) : null;

                    return (
                        <>
                            <MapContent
                                map={map}
                                height={height}
                                showScale={showScale}
                                followParticipants={followParticipants}
                                onFollowClick={() => dispatch({type: TOGGLE_FOLLOW_PARTICIPANTS})}
                                onFitClick={fitToTrailBounds}
                                onElevationGraphIconClick={onElevationGraphIconClick}
                                isElevationGraphDisplayed={isElevationGraphDisplayed}
                                onChangeMapStyle={onChangeMapStyle}
                                toggleSatelliteStyle={toggleSatelliteStyle}
                                hasElevationGraph={hasElevationGraph}
                            />
                            {overlayFilename && (
                                <>
                                    <Source
                                        id={overlayFilename}
                                        tileJsonSource={{
                                            type: 'image',
                                            url: overlayUrl,
                                            coordinates: overlayCoordinates
                                        }}
                                    />
                                    <Layer id={`${overlayFilename}-layer`} type="raster" sourceId={overlayFilename}/>
                                </>
                            )}
                            {gpxTileset && (
                                <>
                                    <Source
                                        id={gpxTileset}
                                        tileJsonSource={{
                                            type: 'vector',
                                            url: `mapbox://${gpxTileset}`,
                                        }}
                                    />
                                    <Layer
                                        type='line'
                                        id={gpxTileset}
                                        sourceId={gpxTileset}
                                        sourceLayer='tracks'
                                        layout={{
                                            'line-join': 'round',
                                            'line-cap': 'round',
                                        }}
                                        paint={{
                                            'line-color': activeTrail.gpxLineColor || '#651a98',
                                            'line-width': activeTrail.gpxLineWidth || 3,
                                        }}
                                    />
                                </>
                            )}
                        </>
                    );
                }}
            </MapContext.Consumer>
        </Mapbox>
    );
}

function MapContent({
                        map,
                        height,
                        onFitClick,
                        onElevationGraphIconClick,
                        isElevationGraphDisplayed,
                        onFollowClick,
                        followParticipants,
                        onChangeMapStyle,
                        toggleSatelliteStyle,
                        hasElevationGraph
                    }) {
    const {
        state: {activeRace, activeTrail, participantPoints, selectedParticipants},
    } = useContext(Context);

    const [trailMarkers, setTrailMarkers] = useState([]);

    const mapRef = useRef();
    mapRef.current = map;
    useEffect(() => {
        mapRef.current.resize();
    }, [height]);

    useEffect(() => {
        setTrailMarkers(
            activeTrail
                ? [
                    {
                        name: 'Start',
                        lngLat: [activeTrail.startLong, activeTrail.startLat],
                    },
                    {
                        name: 'Finish',
                        lngLat: [activeTrail.finishLong, activeTrail.finishLat],
                    },
                    ...activeTrail.checkpoints.map((checkpoint) => ({
                        name: checkpoint.name,
                        lngLat: [checkpoint.long, checkpoint.lat],
                    })),
                ]
                : []
        );
    }, [activeTrail, activeRace]);

    return (
        <>
            {!!participantPoints.length && participantPoints.map((participant) => {
                const {id, points, hexValue, userName} = participant;

                if (points && points.length > 0) {
                    const isSelected = selectedParticipants.indexOf(id) >= 0;

                    const lastPointIndex = points.length - 1;
                    const lastCoordinate = [points[lastPointIndex].long, points[lastPointIndex].lat];

                    const mappedPoints = points.map((point) => [point.long, point.lat]);
                    return (
                        <Fragment key={id}>
                            <Layer type='line' layout={lineLayout} paint={linePaint(hexValue)}>
                                <Feature coordinates={mappedPoints}/>
                            </Layer>

                            <Marker coordinates={lastCoordinate} anchor='center'>
                                {isSelected ? (
                                    <div className='participant-marker'>
                                        <div
                                            className='participant-marker-text'
                                            style={{
                                                backgroundColor: `${hexValue}CC`,
                                                color: isDark(hexValue) ? '#fff' : '#000',
                                            }}
                                        >
                                            {userName}
                                        </div>
                                        <ParticipantMarker fill={hexValue}/>
                                    </div>
                                ) : (
                                    <div className='circle-marker' style={{backgroundColor: hexValue}}/>
                                )}
                            </Marker>
                        </Fragment>
                    );
                }
                return null;
            })}
            {trailMarkers.map((trailPoint, index) => {
                const isStartEnd = trailPoint.name === 'Start' || trailPoint.name === 'Finish';
                return (
                    <Fragment key={`${trailPoint.name}-${index}`}>
                        <Marker coordinates={trailPoint.lngLat} anchor='center'>
                            <div className='race-point-container'>
                                <div
                                    className={`race-point-label ${isStartEnd ? 'start-end' : 'checkpoint'}`}>{trailPoint.name}</div>
                                <img className='race-point' alt='' src={isStartEnd ? endpointSvg : checkpointSvg}/>
                            </div>
                        </Marker>
                    </Fragment>
                );
            })}
            {activeRace && !activeTrail && (
                <Marker coordinates={[activeRace.locationLong, activeRace.locationLat]} anchor='center'>
                    <div className='race-point-container'>
                        <div className='race-point-label start-end'>{activeRace.raceName}</div>
                        <img className='race-point' alt='' src={endpointSvg}/>
                    </div>
                </Marker>
            )}
            {activeTrail && (
                <div className={`map-controls${hasElevationGraph ? "": " no-graph"}`}>
                    <IconButton icon={fitBoundsSvg} onClick={onFitClick}/>
                    <IconButton
                        icon={followParticipants ? followParticipantsActiveSvg : followParticipantsSvg}
                        onClick={onFollowClick}
                        className={followParticipants ? "active" : ""}/>
                    <IconButton
                        icon={toggleSatelliteStyle ? changeMapViewSatellite : changeMapView}
                        onClick={onChangeMapStyle}
                        className={toggleSatelliteStyle ? "active" : ""}/>
                    {hasElevationGraph && (
                        <IconButton
                            icon={isElevationGraphDisplayed ? elevationGraphActiveSvg : elevationGraphSvg}
                            onClick={onElevationGraphIconClick}
                            className={isElevationGraphDisplayed ? "active" : ""}/>
                    )}
                </div>
            )}
        </>
    );
}

function buildMapProps(activeTrail, activeRace, fitBoundsOptions) {
    if (activeTrail) {
        return {
            fitBounds: getTrailBounds(activeTrail),
            fitBoundsOptions,
        };
    }

    if (activeRace) {
        return {
            fitBounds: getBounds(activeRace.locationLong, activeRace.locationLat, 20000),
        };
    }

    return defaultMapProps;
}

function getTrailBounds(activeTrail) {
    const longitudes = [activeTrail.startLong, activeTrail.finishLong];
    const latitudes = [activeTrail.startLat, activeTrail.finishLat];

    activeTrail.checkpoints.forEach((checkpoint) => {
        longitudes.push(checkpoint.long);
        latitudes.push(checkpoint.lat);
    });

    return [
        [Math.min(...longitudes), Math.min(...latitudes)],
        [Math.max(...longitudes), Math.max(...latitudes)],
    ];
}

function getBounds(centerLon, centerLat, distanceM) {
    const ll = new LngLat(centerLon, centerLat);

    return ll.toBounds(distanceM).toArray();
}

function getSelectedParticipantsBounds(participants, selectedParticipants) {
    return selectedParticipants.reduce((bounds, id) => {
        const participant = findSelectedParticipant(participants, id);

        if (participant && participant.points?.length > 0) {
            const long = participant.points[participant.points.length - 1].long;
            const lat = participant.points[participant.points.length - 1].lat;

            return bounds == null
                ? [
                    [long, lat],
                    [long, lat],
                ]
                : [
                    [Math.min(bounds[0][0], long), Math.min(bounds[0][1], lat)],
                    [Math.max(bounds[0][0], long), Math.max(bounds[0][1], lat)],
                ];
        }

        return bounds;
    }, null);
}

function findSelectedParticipant(participants, selectedId) {
    return participants.find((p) => Number(p.id) === Number(selectedId));
}
