export const URL_MAIN = ``;

export const URL_RACE_LIST = `${URL_MAIN}/race`;
export const URL_RACE_DETAIL = `${URL_RACE_LIST}/:raceId`;
export const getRaceDetailUrl = (raceId) =>
  URL_RACE_DETAIL.replace(':raceId', raceId);

export const URL_TRAIL_DETAIL = `${URL_MAIN}/trail/:trailId`;
export const getTrailDetailUrl = (trailId) =>
  URL_TRAIL_DETAIL.replace(':trailId', trailId);

export const URL_TRAIL_REPLAY = `${URL_TRAIL_DETAIL}/replay`;
export const getTrailReplayUrl = (trailId) =>
  URL_TRAIL_REPLAY.replace(':trailId', trailId);
