import { ParticipantList } from 'components/ParticipantList/ParticipantList';
import React, { useContext, useEffect, forwardRef } from 'react';
import { getParticipants, getPointsReplay } from 'service/api';
import { Context, TRAIL_LOADED, REPLAY_POINTS_LOADED, PARTICIPANTS_LOADED, PARTICIPANTS_UNLOADED } from 'context/Context';
import { useParams } from 'react-router-dom';

export const Replay = forwardRef(function Replay({ className }, ref) {
    const { raceId, trailId } = useParams();
    const {
        state: { activeRace },
        dispatch,
    } = useContext(Context);

    useEffect(() => {
        dispatch({
            type: TRAIL_LOADED,
            payload: { raceId, trailId, replay: true },
        });

        if (activeRace) {
            getParticipants(trailId).then((participants) => {
                if (participants.status === 'error') {
                    return;
                }

                const participantIds = participants.map((p) => p.id);

                getPointsReplay(trailId, participantIds, activeRace.apiVersion).then((points) => {
                    dispatch({ type: REPLAY_POINTS_LOADED, payload: { points } });
                });

                dispatch({ type: PARTICIPANTS_LOADED, payload: { participants } });
            });
        }

        return () => {
            dispatch({ type: PARTICIPANTS_UNLOADED });
        };
    }, [dispatch, raceId, trailId, activeRace]);

    return <ParticipantList className={className} getStatusLabel={(s) => s.labelLive} ref={ref}></ParticipantList>;
});
