import moment from 'moment';

const dateTimeFormat = 'MMMM DD YYYY, HH:mm';
const dateTimeFormatThisYear = 'MMMM DD, HH:mm';

export function formatMoment(m) {
  const now = moment();
  const dateFormat =
    m.year() === now.year() ? dateTimeFormatThisYear : dateTimeFormat;

  return m.format(dateFormat);
}

export function formatDuration(duration) {
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = Math.floor(duration.asHours());

  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  return `00:${formatInt(seconds)}`;
}

function formatInt(int) {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
}
