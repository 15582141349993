import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import ReactSlider from "react-slider";
import {
    Context,
    INCREASE_PLAYBACK_SPEED,
    PAUSE_PLAYBACK,
    PLAYBACK_DURATION_CHANGED,
    PLAYBACK_TICK,
    START_PLAYBACK
} from 'context/Context';
import {IconButton} from 'components/IconButton/IconButton';
import playIcon from 'assets/svg/play_icon.svg';
import pauseIcon from 'assets/svg/icon_pause.svg';
import './PlaybackControls.scss';

export const PlaybackControls = forwardRef(function ({className}, ref) {
    const {
        state: {playback, raceDuration, enableSlider},
        dispatch,
    } = useContext(Context);

    const playbackStatus = playback.status;
    const isPlaying = playbackStatus === 'PLAYING';

    const sliderRef = useRef(null);
    const [animationFrameCount, setAnimationFrameCount] = useState(0);
    const [raceMoment, setRaceMoment] = useState(0);
    const [isSliderDisabled, setIsSliderDisabled] = useState(true);
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (raceMoment >= 100) {
            dispatch({type: PAUSE_PLAYBACK});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [raceMoment]);

    useEffect(() => {
        if (!isFirstRender) {
            setIsSliderDisabled(raceDuration === 0);
            sliderRef.current.handleResize();
        }
    }, [raceDuration, isFirstRender]);

    useEffect(() => {
        setRaceMoment(playback.duration * 100 / raceDuration);
    }, [playback, raceDuration]);

    useEffect(() => {
        if (enableSlider) {
            setIsFirstRender(false);
        }
    }, [enableSlider]);

    useEffect(() => {
        if (playback.status === 'PLAYING') {
            requestAnimationFrame(() => {
                if (animationFrameCount === 0) {
                    dispatch({type: PLAYBACK_TICK});
                }

                setAnimationFrameCount(animationFrameCount < 3 ? animationFrameCount + 1 : 0);
            });
        }
    }, [playback, dispatch, animationFrameCount]);

    const onThumbDrag = (value) => {
        setRaceMoment(value);
        dispatch({
            type: PLAYBACK_DURATION_CHANGED,
            payload: {duration: Math.ceil((raceDuration * value) / 100)},
        });
    };

    const onSpeedAccelerationClick = () => {
        dispatch({type: INCREASE_PLAYBACK_SPEED});
    };

    const onPlayPauseClick = () => {
        dispatch({type: isPlaying ? PAUSE_PLAYBACK : START_PLAYBACK})
    };

    return (
        <div className={`playback-controls ${className || ''}`} ref={ref}>
            <div className='controls'>
                <div className="play-controls">
                    <IconButton
                        className='play-pause'
                        icon={isPlaying ? pauseIcon : playIcon}
                        disabled={raceMoment >= 100}
                        onClick={onPlayPauseClick}
                    />
                    <button onClick={onSpeedAccelerationClick} className="icon-button speed-btn">
                        <p className='current-speed'>{playback.speed}X</p>
                    </button>
                </div>
            </div>
            <ReactSlider
                className={`scroller${raceDuration === 0 ? ' disabled' : ''}`}
                thumbClassName='marker'
                trackClassName='track'
                value={raceMoment}
                disabled={isSliderDisabled}
                ref={sliderRef}
                onChange={onThumbDrag}
                renderThumb={(props) => (
                    <div {...props} className='marker'/>
                )}
            />
        </div>
    );
});
