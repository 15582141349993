import React from 'react';
import './VirtualTypeLabel.scss';

const virtualTypes = {
  1: { className: 'virtual', label: 'Virtual race' },
  2: { className: 'challenge', label: 'Challenge' },
};

export function VirtualTypeLabel({ type, className }) {
  const typeConfig = virtualTypes[type];

  return (
    <span
      className={`virtual-race-label ${typeConfig.className} ${
        className || ''
      }`}
    >
      {typeConfig.label}
    </span>
  );
}
