import React, { useReducer } from 'react';
import moment from 'moment';
import { PARTICIPANT_STATUSES, PARTICIPANT_STATUS_RUNNING, PARTICIPANT_STATUS_WAITING } from 'helpers/statusHelper';
export const TOGGLE_ENABLE_SLIDER = 'toggle-enable-slider';
export const RACE_LIST_LOADED = 'race-list-loaded';
export const TOGGLE_RACE_LIST_FILTER = 'toggle-race-list-filter';
export const RACE_LOADED = 'race-loaded';
export const RACE_UNLOADED = 'race-unloaded';
export const TRAIL_LOADED = 'trail-loaded';
export const TRAIL_UNLOADED = 'trail-unloaded';
export const PARTICIPANTS_LOADED = 'participants-loaded';
export const PARTICIPANTS_UNLOADED = 'participants-unloaded';
export const POINTS_LOADED = 'points-participants';
export const TOGGLE_PARTICIPANT = 'toggle-participant';
export const TOGGLE_PARTICIPANTS = 'toggle-participants';
export const REPLAY_POINTS_LOADED = 'replay-points-loaded';
export const START_PLAYBACK = 'start-playback';
export const PAUSE_PLAYBACK = 'pause-playback';
export const INCREASE_PLAYBACK_SPEED = 'increase-playback-speed';
export const PLAYBACK_TICK = 'playback-tick';
export const PLAYBACK_DURATION_CHANGED = 'playback-duration-changed';
export const TOGGLE_PARTICIPANT_LIST = 'toggle-participant-list';
export const TOGGLE_FOLLOW_PARTICIPANTS = 'toggle-follow-participants';
export const STOP_FOLLOW_PARTICIPANTS = 'stop-follow-participants';

const initialState = {
    raceList: [],
    raceListDistanceFilter: null,
    activeRaceId: null,
    activeRace: null,
    activeTrailId: null,
    activeTrail: null,
    participants: [],
    liveWatch: false,
    participantPoints: [],
    selectedParticipants: [],

    //replay
    replay: false,
    replayPoints: [],
    raceDuration: 0,
    playback: { status: 'STOPPED', duration: 0, speed: 1 },
};

const playbackSpeedOptions = [1, 2, 5, 10, 20, 30];

function reducer(state, action) {
    const payload = action.payload;

    switch (action.type) {
        case RACE_LIST_LOADED: {
            const raceList = payload.raceList;

            let activeTrail = state.activeTrail;
            let activeRace = null;
            if (state.activeTrailId) {
                activeTrail = findTrail(raceList, state.activeTrailId);
                activeRace = raceList.find((r) => findByIdNum(r.trails, activeTrail.id) != null);
            } else if (state.activeRaceId) {
                activeRace = findByIdNum(raceList, state.activeRaceId);
            }

            return {
                ...state,
                raceList,
                activeRaceId: activeRace ? activeRace.id : null,
                activeRace,
                activeTrail,
                activeTrailId: activeTrail ? activeTrail.id : null,
                ...initRacePlaybackState(activeRace, state.replayPoints, state.playback.duration),
            };
        }
        case TOGGLE_RACE_LIST_FILTER: {
            return { ...state, raceListDistanceFilter: payload.distance };
        }
        case RACE_LOADED: {
            const raceId = payload.raceId;

            return {
                ...state,
                activeRaceId: raceId,
                activeRace: raceId == null ? null : findByIdNum(state.raceList, raceId),
            };
        }
        case RACE_UNLOADED: {
            return { ...state, activeRaceId: null, activeRace: null };
        }
        case TRAIL_LOADED: {
            const trailId = payload.trailId;
            const activeTrail = findTrail(state.raceList, trailId);
            const activeRace = (state.raceList || []).find((r) => findByIdNum(r.trails, activeTrail.id) != null);
            const activeRaceId = activeRace ? activeRace.id : null;

            return {
                ...state,
                activeRaceId,
                activeRace,

                activeTrailId: trailId,
                activeTrail: activeTrail ? { ...activeTrail } : null,

                liveWatch: payload.liveWatch === true,
                replay: payload.replay === true,
            };
        }
        case TRAIL_UNLOADED: {
            return {
                ...state,
                activeTrailId: null,
                activeTrail: null,
                participants: [],
                liveWatch: false,
                replay: false,
                participantPoints: [],
                selectedParticipants: [],
                raceDuration: 0,
                replayPoints: [],
                playback: { status: 'STOPPED', duration: 0, speed: 1 },
                followParticipants: false,
            };
        }
        case PARTICIPANTS_LOADED: {
            return { ...state, participants: payload.participants };
        }
        case PARTICIPANTS_UNLOADED: {
            return { ...state, participants: [], participantPoints: [] };
        }
        case POINTS_LOADED: {
            return { ...state, participantPoints: payload.points };
        }
        case TOGGLE_PARTICIPANT: {
            const id = payload.participant.id;
            const selection = state.selectedParticipants;
            const selectedIndex = selection.indexOf(id);
            const newSelection = selectedIndex >= 0 ? [...selection.slice(0, selectedIndex), ...selection.slice(selectedIndex + 1)] : [...selection, id];

            return {
                ...state,
                selectedParticipants: newSelection,
                followParticipants: newSelection.length > 0,
            };
        }
        case TOGGLE_PARTICIPANTS: {
            const selectedParticipants = payload.selectedParticipants;
            return {
                ...state,
                selectedParticipants: selectedParticipants.map((p) => p.id),
                followParticipants: selectedParticipants.length > 0,
            };
        }
        case REPLAY_POINTS_LOADED: {
            const points = payload.points;
            const { activeRace, playback } = state;
            const playbackState = { ...playback, duration: 0 };

            return {
                ...state,
                replayPoints: points,
                playback: playbackState,
                ...initRacePlaybackState(activeRace, points, playbackState.duration),
            };
        }
        case START_PLAYBACK: {
            const playbackState = state.playback;
            const playbackStatus = playbackState.status;

            let newPlaybackState;
            if (playbackStatus === 'STOPPED') {
                newPlaybackState = {
                    status: 'PLAYING',
                    lastUpdateTime: moment(),
                    playbackDuration: 0,
                };
            } else if (playbackStatus === 'PAUSED') {
                newPlaybackState = { status: 'PLAYING', lastUpdateTime: moment() };
            }

            return { ...state, playback: { ...playbackState, ...newPlaybackState } };
        }
        case PAUSE_PLAYBACK: {
            const playbackState = state.playback;

            return { ...state, playback: { ...playbackState, status: 'PAUSED' } };
        }
        case INCREASE_PLAYBACK_SPEED: {
            const playbackState = state.playback;
            const speed = playbackState.speed;
            const newSpeed = playbackSpeedOptions[playbackSpeedOptions.indexOf(speed) + 1] || playbackSpeedOptions[0];

            return { ...state, playback: { ...playbackState, speed: newSpeed } };
        }
        case PLAYBACK_TICK: {
            const playbackState = state.playback;

            const now = moment();
            const intervalDuration = now.diff(playbackState.lastUpdateTime) * playbackState.speed;
            const participantPoints = generatePlaybackPoints(state.activeRace, state.replayPoints, playbackState.duration, state.paybackRaceStartTime);
            const participants = reorderParticipants(state.participants, participantPoints, state.activeTrail);
            return {
                ...state,
                playback: {
                    ...playbackState,
                    lastUpdateTime: now,
                    duration: playbackState.duration + intervalDuration,
                },
                participantPoints,
                participants,
            };
        }
        case PLAYBACK_DURATION_CHANGED: {
            return {
                ...state,
                playback: { ...state.playback, duration: payload.duration },
            };
        }
        case TOGGLE_PARTICIPANT_LIST: {
            return {
                ...state,
                participantListCollapsed: !state.participantListCollapsed,
            };
        }
        case TOGGLE_FOLLOW_PARTICIPANTS: {
            return {
                ...state,
                followParticipants: !state.followParticipants,
            };
        }
        case STOP_FOLLOW_PARTICIPANTS: {
            return {
                ...state,
                followParticipants: false,
            };
        }
        case TOGGLE_ENABLE_SLIDER: {
            return {
                ...state,
                enableSlider: payload.enableSlider,
            };
        }
        default:
            throw new Error(`Unknown action ${action.type}`);
    }
}
export const Context = React.createContext(initialState);

export function ContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
}

function findByIdNum(array, id) {
    return array.find((e) => Number(e.id) === Number(id));
}

function findTrail(raceList, trailId) {
    const allTrails = raceList.map((r) => r.trails).reduce((f, t) => [...f, ...t], []);
    return findByIdNum(allTrails, trailId);
}

function getRaceInterval(race, points) {
    if (race.virtualType === 2) {
        // the longest chellange
        return points.reduce(
            ([startTime, endTime], p) => {
                if (!p.points || p.points.length === 0) {
                    return [startTime, endTime];
                }

                const firstPointTime = p.points[0].timestamp;
                const lastPointTime = p.points[p.points.length - 1].timestamp;

                return endTime - startTime > lastPointTime - firstPointTime ? [startTime, endTime] : [firstPointTime, lastPointTime];
            },
            [Number.MAX_SAFE_INTEGER, 0]
        );
    }

    // from the closest time to the race start to the closest one to the race end
    const [pointsStartTime, pointsEndTime] = points.reduce(
        ([startTime, endTime], p) => {
            if (!p.points || p.points.length === 0) {
                return [startTime, endTime];
            }

            const firstPointTime = p.points[0].timestamp;
            const lastPointTime = p.points[p.points.length - 1].timestamp;

            const start = Math.min(startTime, firstPointTime);
            const end = lastPointTime > start ? Math.max(endTime, lastPointTime) : endTime;

            return [start, end];
        },
        [Number.MAX_SAFE_INTEGER, 0]
    );

    return [Math.max(race.startTime, pointsStartTime), Math.min(race.endTime, pointsEndTime)];
}

function generatePlaybackPoints(race, points, duration, startTime) {
    return points.map((p) => {
        const points = p.points;

        if (points.length === 0) {
            return p;
        }

        const [intervalEndTime, intervalLastPointIndex] = getPlaybackIntervalInfo(race, points, duration, startTime);

        const pointsInInterval = points.slice(Math.max(0, intervalLastPointIndex - race.tailLength), intervalLastPointIndex);

        let stats = points[0].stats;

        if (pointsInInterval.length > 0) {
            stats = pointsInInterval[pointsInInterval.length - 1]?.stats;

            if (stats.status === PARTICIPANT_STATUS_RUNNING && intervalLastPointIndex > 0 && intervalLastPointIndex < points.length) {
                // add dummy point for animation
                const animationPoint = generateAnimationPoint(points, intervalLastPointIndex, intervalEndTime);
                if (animationPoint != null) {
                    pointsInInterval.push(animationPoint);
                }
            } else if (PARTICIPANT_STATUSES[stats.status].isFinished) {
                stats = p.stats;
            }
        }

        return {
            ...p,
            points: pointsInInterval,
            stats,
        };
    });
}

function getPlaybackIntervalInfo(race, points, duration, startTime) {
    const pointCount = points.length;
    const firstPoint = points[0];
    const lastPoint = points[pointCount - 1];

    const playbackStartTime = race.virtualType === 2 ? toMillis(firstPoint.timestamp) : toMillis(startTime);

    // const startTartTime = toMillis(firstPoint.timestamp);

    const intervalEndTime = playbackStartTime + duration;
    const intervalLastPointIndex =
        toMillis(firstPoint.timestamp) > intervalEndTime
            ? 1
            : toMillis(lastPoint.timestamp) < intervalEndTime
            ? pointCount
            : points.findIndex((point) => toMillis(point.timestamp) > intervalEndTime);

    return [intervalEndTime, intervalLastPointIndex];
}

function generateAnimationPoint(points, intervalLastPointIndex, intervalEndTime) {
    const lastPoint = points[intervalLastPointIndex - 1];
    const nextPoint = points[intervalLastPointIndex];

    if (nextPoint.timestamp - lastPoint.timestamp > 0 && nextPoint.long !== lastPoint.long && nextPoint.lat !== lastPoint.lat) {
        var start = [lastPoint.long, lastPoint.lat];
        var end = [nextPoint.long, nextPoint.lat];
        var distance = gis.calculateDistance(start, end);

        if (distance > 0) {
            var current_distance =
                ((intervalEndTime - toMillis(lastPoint.timestamp)) * distance) / (toMillis(nextPoint.timestamp) - toMillis(lastPoint.timestamp));
            // Avoid calculating negative distances:
            current_distance = Math.max(0, current_distance);
            var bearing = gis.getBearing(start, end);
            var [currentLong, currentLat] = gis.createCoord(start, bearing, current_distance);

            return {
                ...lastPoint,
                long: currentLong,
                lat: currentLat,
            };
        }
    }

    return null;
}

const finishedStatuses = Object.keys(PARTICIPANT_STATUSES)
    .filter((s) => PARTICIPANT_STATUSES[s].isFinished)
    .map(Number);
const activeStatuses = Object.keys(PARTICIPANT_STATUSES)
    .filter((s) => PARTICIPANT_STATUSES[s].isActive)
    .map(Number);
function reorderParticipants(participants, playbackPoints, activeTrail) {
    const groups = participants.reduce(
        (grouped, p) => {
            const points = findByIdNum(playbackPoints, p.id)?.points;
            const status = points && points.length > 0 ? points[points.length - 1].stats.status : p.status;

            if (finishedStatuses.includes(status)) {
                return {
                    ...grouped,
                    finished: [...grouped.finished, p],
                };
            } else if (activeStatuses.includes(status)) {
                return {
                    ...grouped,
                    active: [...grouped.active, p],
                };
            } else {
                return {
                    ...grouped,
                    inactive: [...grouped.inactive, p],
                };
            }
        },
        {
            finished: [],
            active: [],
            inactive: [],
        }
    );

    return [...orderByDuration(groups.finished), ...orderByTarget(groups.active, playbackPoints, activeTrail), ...groups.inactive.sort(userNameSorter)];
}

function orderByDuration(participants) {
    return participants.sort((p1, p2) => {
        const durationDiff = p1.finishTime - p1.startTime - (p2.finishTime - p2.startTime);

        if (durationDiff === 0) {
            return userNameSorter(p1, p2);
        }

        return durationDiff;
    });
}

function orderByTarget(participants, playbackPoints, trail) {
    return participants.sort((p1, p2) => {
        const points1 = findByIdNum(playbackPoints, p1.id);
        const stats1 = points1 && points1.points.length > 0 ? points1.points[points1.points.length - 1].stats : null;
        const points2 = findByIdNum(playbackPoints, p2.id);
        const stats2 = points2 && points2.points.length > 0 ? points2.points[points2.points.length - 1].stats : null;

        if (stats1 == null && stats2 == null) {
            return userNameSorter(p1, p2);
        } else if (stats1 == null) {
            return 1;
        } else if (stats2 == null) {
            return -1;
        }

        let diff;
        if (!trail.targetDistance && !trail.targetElevation) {
            diff = stats2.currentDistanceM - stats1.currentDistanceM;
        } else if (trail.targetDistance > 0 && trail.targetElevation > null) {
            diff = stats2.currentDistanceM - stats1.currentDistanceM + (stats2.currentElevationM - stats1.currentElevationM);
        } else if (stats1.targetDistanceM != null) {
            diff = stats2.currentDistanceM - stats1.currentDistanceM;
        } else {
            diff = stats2.currentElevationM - stats1.currentElevationM;
        }

        return diff === 0 ? userNameSorter(p1, p2) : diff;
    });
}

function userNameSorter(p1, p2) {
    const p1Name = p1.userName.toLowerCase();
    const p2Name = p2.userName.toLowerCase();

    return p1Name > p2Name ? 1 : p1Name < p2Name ? -1 : 0;
}

function toMillis(uTimestamp) {
    return uTimestamp * 1000;
}

function initRacePlaybackState(activeRace, replayPoints, playbackDuration) {
    if (!activeRace || !replayPoints || replayPoints.length === 0) {
        return {};
    }

    const [start, end] = getRaceInterval(activeRace, replayPoints);
    const raceDuration = end > start ? moment.duration(moment.unix(end).diff(moment.unix(start))).asMilliseconds() : 0;

    const paybackRaceStartTime = start;
    const participantPoints = generatePlaybackPoints(activeRace, replayPoints, playbackDuration, paybackRaceStartTime).map((p) => ({
        ...p,
        stats: { ...p.statas, status: PARTICIPANT_STATUS_WAITING },
    }));

    return {
        paybackRaceStartTime,
        raceDuration,
        participantPoints,
    };
}

const gis = {
    /**
     * All coordinates expected EPSG:4326
     * @param {Array} start Expected [lon, lat]
     * @param {Array} end Expected [lon, lat]
     * @return {number} Distance - meter.
     */
    calculateDistance: function (start, end) {
        const lat1 = parseFloat(start[1]),
            lon1 = parseFloat(start[0]),
            lat2 = parseFloat(end[1]),
            lon2 = parseFloat(end[0]);

        return gis.sphericalCosinus(lat1, lon1, lat2, lon2);
    },

    /**
     * All coordinates expected EPSG:4326
     * @param {number} lat1 Start Latitude
     * @param {number} lon1 Start Longitude
     * @param {number} lat2 End Latitude
     * @param {number} lon2 End Longitude
     * @return {number} Distance - meters.
     */
    sphericalCosinus: function (lat1, lon1, lat2, lon2) {
        const radius = 6371e3; // meters
        const dLon = gis.toRad(lon2 - lon1);

        lat1 = gis.toRad(lat1);
        lat2 = gis.toRad(lat2);

        const distance = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(dLon)) * radius;

        return distance;
    },

    /**
     * @param {Array} coord Expected [lon, lat] EPSG:4326
     * @param {number} bearing Bearing in degrees
     * @param {number} distance Distance in meters
     * @return {Array} Lon-lat coordinate.
     */
    createCoord: function (coord, bearing, distance) {
        /** http://www.movable-type.co.uk/scripts/latlong.html
         * φ is latitude, λ is longitude,
         * θ is the bearing (clockwise from north),
         * δ is the angular distance d/R;
         * d being the distance travelled, R the earth’s radius*
         **/
        const radius = 6371e3, // meters
            δ = Number(distance) / radius, // angular distance in radians
            θ = gis.toRad(Number(bearing)),
            φ1 = gis.toRad(coord[1]),
            λ1 = gis.toRad(coord[0]);

        const φ2 = Math.asin(Math.sin(φ1) * Math.cos(δ) + Math.cos(φ1) * Math.sin(δ) * Math.cos(θ));

        let λ2 = λ1 + Math.atan2(Math.sin(θ) * Math.sin(δ) * Math.cos(φ1), Math.cos(δ) - Math.sin(φ1) * Math.sin(φ2));
        // normalise to -180..+180°
        λ2 = ((λ2 + 3 * Math.PI) % (2 * Math.PI)) - Math.PI;

        return [gis.toDeg(λ2), gis.toDeg(φ2)];
    },
    /**
     * All coordinates expected EPSG:4326
     * @param {Array} start Expected [lon, lat]
     * @param {Array} end Expected [lon, lat]
     * @return {number} Bearing in degrees.
     */
    getBearing: function (start, end) {
        const startLat = gis.toRad(start[1]),
            startLong = gis.toRad(start[0]),
            endLat = gis.toRad(end[1]),
            endLong = gis.toRad(end[0]);

        let dLong = endLong - startLong;

        var dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0));

        if (Math.abs(dLong) > Math.PI) {
            dLong = dLong > 0.0 ? -(2.0 * Math.PI - dLong) : 2.0 * Math.PI + dLong;
        }

        return (gis.toDeg(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
    },
    toDeg: function (n) {
        return (n * 180) / Math.PI;
    },
    toRad: function (n) {
        return (n * Math.PI) / 180;
    },
};
