import React from 'react'
import iconTick from "assets/svg/icon_tick.svg";
import "./Checkbox.scss";

const Checkbox = ({ className, checked, onChange }) => (
  <div className={`checkbox-container ${className || ""}`}>
    <button className={`styled-checkbox${checked ? " checked" : ""}`} onClick={onChange}>
      {checked && <img src={iconTick} alt=""/>}
    </button>
  </div>
);

export default Checkbox;
