import React from "react";
import downloadIcon from 'assets/svg/icon_download.svg';
import './DownloadButton.scss';

const DownloadButton = ({ onClick, children }) => (
  <button className="gpx-download" onClick={onClick}>
    <div className="btn-content">
      <img src={downloadIcon} alt="" />
      {children}
    </div>
  </button>
);

export default DownloadButton;
