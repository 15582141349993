import React, {Component, forwardRef, useContext, useEffect, useRef, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';
import {LiveWatch} from 'components/LiveWatch/LiveWatch';
import {Logo} from 'components/Logo/Logo';
import {Map} from 'components/Map/Map';
import {RaceList} from 'components/RaceList/RaceList';
import {RaceListHeader} from 'components/RaceList/RaceListHeader';
import {Replay} from 'components/Replay/Replay';
import {TrailDetailHeader} from 'components/TrailDetailHeader/TrailDetailHeader';
import {TrailList} from 'components/TrailList/TrailList';
import {TrailListHeader} from 'components/TrailList/TrailListHeader';
import ElevationGraph from "components/ElevationGraph/ElevationGraph";
import {
    Context,
    ContextProvider,
    RACE_LIST_LOADED,
    RACE_UNLOADED,
    TOGGLE_ENABLE_SLIDER,
    TRAIL_UNLOADED
} from 'context/Context';
import {MOBILE_BREAKPOINT} from 'helpers/styleHelper';
import {getElevationData, getOverlayData, getRaceList} from 'service/api';
import {URL_RACE_DETAIL, URL_RACE_LIST, URL_TRAIL_DETAIL, URL_TRAIL_REPLAY} from 'url.js';
import 'App.scss';
import {Helmet} from "react-helmet";

class App extends Component {
    render() {
        return (
            <ContextProvider>
                <BrowserRouter>
                    <AppContent {...this.state}/>
                </BrowserRouter>
            </ContextProvider>
        );
    }
}

export default App;

const RaceListRoute = withActivatedCallback(RaceList);
const LiveRoute = withActivatedCallback(LiveWatch);
const ReplayRoute = withActivatedCallback(Replay);

const fitBoundsOptionsMobile = {
    padding: {top: 100, left: 50, bottom: 50, right: 50},
};
const fitBoundsOptionsDesktop = {
    padding: {top: 150, left: 250, bottom: 150, right: 150},
};

function AppContent() {
    const {
        state: {
            raceList,
            raceListDistanceFilter,
            activeTrail,
            activeTrailId,
            replay,
            activeRace,
            participantListCollapsed
        },
        dispatch,
    } = useContext(Context);

    const location = useLocation();
    const mapRef = useRef();
    const navHeaderRef = useRef();
    const navContentRef = useRef();
    const appRef = useRef();
    const [trailActive, setTrailActive] = useState(false);
    const [appWidth, setAppWidth] = useState(document.body.offsetWidth);
    const isMobile = appWidth <= MOBILE_BREAKPOINT;
    const [isFirstLayout, setFirstLayout] = useState(true);
    const [isElevationGraphDisplayed, setIsElevationGraphDisplayed] = useState(!isMobile);
    const [elevationData, setElevationData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [precision, setPrecision] = useState({elevation: 0, coord: 0});
    const [overlay, setOverlay] = useState({});
    const [trailId, setTrailId] = useState(null);
    const [mapHeight, setMapHeight] = useState('100vh');
    const appleiTunesAppContent = "app-id=id1450648952, app-argument=" + window.location.href + "";

    useEffect(() => {
        let observer;
        const currentAppRef = appRef.current;
        if (currentAppRef) {
            observer = new ResizeObserver((entries) => {
                // Only care about the first element, we expect one element ot be watched
                const {width} = entries[0].contentRect;

                setAppWidth(width);
            });
            observer.observe(appRef.current);
        }

        return () => {
            if (observer) {
                observer.unobserve(currentAppRef);
            }
        };
    }, [appRef]);

    useEffect(() => {
        loadRaces(dispatch, raceListDistanceFilter);
    }, [dispatch, raceListDistanceFilter]);

    useEffect(() => {
        if (!navContentRef.current) {
            return;
        }

        recalculateLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRace, activeTrail, trailActive, appWidth, isMobile, participantListCollapsed]);

    useEffect(() => {
        if (navContentRef.current && isFirstLayout) {
            recalculateLayout();
            setFirstLayout(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navContentRef.current]);

    useEffect(() => {
        if (activeTrailId && activeTrailId !== trailId) {
            setTrailId(activeTrailId);
            getOverlayData(activeTrailId).then((overlay) => {
                if (!overlay) {
                    return null;
                }
                setOverlay(overlay);
            }).catch((e) => console.error(e));
        }
        return () => {
            setOverlay({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTrailId]);

    useEffect(() => {
        if (activeTrailId && activeTrailId !== trailId) {
            setTrailId(activeTrailId);
            getElevationData(activeTrailId).then((
                {
                    elevation,
                    coordinates,
                    elevationPrecision,
                    coordPrecision
                }
            ) => {
                setElevationData(elevation);
                setLabels(coordinates);
                setPrecision({elevation: elevationPrecision, coord: coordPrecision});
            }).catch((e) => console.error(e)).finally(() => dispatch({
                type: TOGGLE_ENABLE_SLIDER,
                payload: {enableSlider: true}
            }));
        }
        return () => {
            setElevationData([]);
            setLabels([]);
            setTrailId(null);
            dispatch({type: TOGGLE_ENABLE_SLIDER, payload: {enableSlider: false}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTrailId]);

    const onElevationGraphIconClick = () => {
        setIsElevationGraphDisplayed(!isElevationGraphDisplayed);
    }

    return (
        <div className={`container${trailActive ? ' trail-view' : ''}`} ref={appRef}>
            <Helmet>
                <meta name="apple-itunes-app" content={appleiTunesAppContent} />
            </Helmet>
            <Logo className='app-logo' replay={replay} activeTrail={activeTrail} activeRace={activeRace}/>

            <div className='map-container' ref={mapRef} style={{height: mapHeight}}>
                <Map
                    height={mapHeight}
                    fitBoundsOptions={isMobile ? fitBoundsOptionsMobile : fitBoundsOptionsDesktop}
                    showScale={!isMobile}
                    overlay={overlay}
                    onElevationGraphIconClick={onElevationGraphIconClick}
                    isElevationGraphDisplayed={isElevationGraphDisplayed}
                    hasElevationGraph={!!elevationData.length} />
                {!!labels.length && !!elevationData.length && isElevationGraphDisplayed && (
                    <ElevationGraph data={elevationData} labels={labels} precision={precision} />)}
            </div>

            <div className='navigation-header' ref={navHeaderRef}>
                <Switch location={location}>
                    <Route exact path={URL_RACE_LIST} component={RaceListHeader}/>
                    <Route exact path={URL_RACE_DETAIL} component={TrailListHeader}/>
                    <Route exact path={[URL_TRAIL_DETAIL, URL_TRAIL_REPLAY]} component={TrailDetailHeader}/>
                </Switch>
            </div>
            <Switch location={location}>
                <Route
                    exact
                    path={URL_RACE_LIST}
                    render={() => {
                        return (
                            <RaceListRoute
                                className='navigation-content'
                                raceList={raceList}
                                onActivated={() => {
                                    setTrailActive(false);
                                }}
                                ref={navContentRef}
                            />
                        );
                    }}
                />
                <Route
                    path=''
                    render={() => {
                        return (
                            <RaceRouter onTrailActivated={() => setTrailActive(true)}
                                        onTrailDeactivated={() => setTrailActive(false)} ref={navContentRef}/>
                        );
                    }}
                />
            </Switch>
        </div>
    );

    function recalculateLayout() {
        const isMobileTrail = trailActive && isMobile;
        const navHeaderTop = navHeaderRef.current.offsetTop + navHeaderRef.current.offsetHeight;

        let navContentTop = `${navHeaderTop}px`;
        navContentRef.current.style.top = isMobileTrail ? '' : navContentTop;

        let mapTop = 0;
        let mapHeight = '100vh';
        if (isMobileTrail) {
            mapTop = `${navHeaderTop}px`;
            mapHeight = `${navContentRef.current.offsetTop - navHeaderTop}px`;
        }

        mapRef.current.style.top = mapTop;
        setMapHeight(mapHeight);
    }
}

function withActivatedCallback(Component) {
    return forwardRef(function WithActivatedCallback({onActivated, ...props}, ref) {
        useEffect(() => {
            onActivated();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <Component {...props} ref={ref}/>;
    });
}

const RaceRouter = forwardRef(function RaceRouter({onTrailActivated, onTrailDeactivated}, ref) {
    const {dispatch} = useContext(Context);
    useEffect(() => {
        return () => {
            dispatch({type: RACE_UNLOADED});
        };
    }, [dispatch]);

    return (
        <Switch>
            <Route exact path={URL_RACE_DETAIL} render={() => <TrailList className='navigation-content' ref={ref}/>}/>
            <Route path='' render={() => <TrailRouter onActivated={onTrailActivated} onDeactivated={onTrailDeactivated}
                                                      ref={ref}/>}/>
        </Switch>
    );
});

const TrailRouter = forwardRef(function TrailRouter({onActivated, onDeactivated}, ref) {
    const {dispatch} = useContext(Context);
    const onDeactivatedRef = useRef();
    onDeactivatedRef.current = onDeactivated || (() => {
    });
    useEffect(() => {
        return () => {
            onDeactivatedRef.current();
            dispatch({type: TRAIL_UNLOADED});
        };
    }, [dispatch]);

    return (
        <Switch>
            <Route exact path={URL_TRAIL_DETAIL}
                   render={() => <LiveRoute className='navigation-content' onActivated={onActivated} ref={ref}/>}/>
            <Route
                exact
                path={URL_TRAIL_REPLAY}
                render={() => <ReplayRoute className='navigation-content' onActivated={onActivated} ref={ref}/>}
            />
            <Redirect from='*' to={URL_RACE_LIST}/>
        </Switch>
    );
});

function loadRaces(dispatch, distance) {
    new Promise((resolve, reject) => {
        if (distance && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                getRaceList(position.coords.latitude, position.coords.longitude, distance).then(resolve).catch(reject);
            });
        } else {
            return getRaceList().then(resolve).catch(reject);
        }
    }).then((raceList) => {
        dispatch({type: RACE_LIST_LOADED, payload: {raceList}});
    });
}
