import React, { forwardRef, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getRaceDetailUrl } from 'url.js';
import { VirtualTypeLabel } from 'components/VirtualTypeLabel/VirtualTypeLabel';
import { Context, TOGGLE_RACE_LIST_FILTER } from 'context/Context';
import { formatMoment } from 'helpers/dateHelper';
import './RaceList.scss';

export const RaceList = forwardRef(function RaceList(
  { className, raceList },
  ref
) {
  const {
    state: { raceListDistanceFilter },
    dispatch,
  } = useContext(Context);

  const upcomingRaceList = raceList;

  return (
    <div className={`race-list ${className || ''}`} ref={ref}>
      <div className="filters">
        <RadioGroup
          options={[
            { value: 30, label: 'Nearby' },
            { value: 100, label: 'Region' },
            { value: null, label: 'All' },
          ]}
          value={raceListDistanceFilter}
          onChange={(value) => {
            dispatch({
              type: TOGGLE_RACE_LIST_FILTER,
              payload: { distance: value },
            });
          }}
        />
      </div>
      <div className="races">
        {!!upcomingRaceList.length && upcomingRaceList.map((race) => {
          const mStartTime = moment.unix(race.startTime);
          const mEndTime = moment.unix(race.endTime);
          const now = moment();

          const startTime = formatMoment(mStartTime);
          const endTime = formatMoment(mEndTime);

          return (
            <div
              key={race.id}
              className="race"
              style={{
                background: `linear-gradient(to right, #FFFFFF 50%, rgba(255,255,255,0.2) 100%), url("${
                  race.headerImg || ''
                }") top right no-repeat`,
              }}
            >
              <div className="race-info">
                <Link to={getRaceDetailUrl(race.id)} className="race-name">
                  <h2>{race.raceName}</h2>
                </Link>
                <div>
                  <span className="date">{`${startTime}${
                    race.virtualType === 2 ? ' - ' + endTime : ''
                  }`}</span>
                  {now.isAfter(mStartTime) && now.isBefore(mEndTime) && (
                    <span className="live">Live</span>
                  )}
                  {race.virtualType > 0 && (
                    <div>
                      <VirtualTypeLabel
                        type={race.virtualType}
                        className="race-list-virtual"
                      />
                    </div>
                  )}
                </div>
                <p className="location">{race.locationName}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

function RadioGroup({ options, value, onChange }) {
  return options.map((o, i) => (
    <label
      className={`radio ${o.value === value ? 'checked' : null}`}
      key={o.value}
    >
      <input
        type="radio"
        value={i}
        checked={value === o.value}
        onChange={() => onChange(o.value)}
      />
      {o.label}
    </label>
  ));
}
