export const PARTICIPANT_STATUS_WAITING = 0;
export const PARTICIPANT_STATUS_RUNNING = 1;

export const PARTICIPANT_STATUSES = {
  [PARTICIPANT_STATUS_WAITING]: {
    ...labels('Participating', 'Did not start', 'Did not finish'),
  },
  [PARTICIPANT_STATUS_RUNNING]: {
    ...labels('Ready', 'Running', 'Did not finish'),
    isActive: true,
  },
  2: { ...labels('Paused', 'Paused', 'Did not finish'), isActive: true },
  3: { ...labels('Stopped', 'Stopped', ''), isFinished: true },
  4: { ...labels('No Signal', 'No Signal', 'Did not finish'), isActive: true },
  5: { ...labels('No GPS', 'No GPS', 'Did not finish'), isActive: true },
  31: { ...labels('Finished', 'Finished', 'Finished'), isFinished: true }, // by going near the finish point
  32: { ...labels('Finished', 'Finished', 'Finished'), isFinished: true }, // by completing the targetDistance and/or targetElevation
  60: { ...labels('Aborted', 'Aborted', 'Aborted after') },
  61: { ...labels('Disqualified', 'Disqualified', 'Disqualified') },
  62: { ...labels('', 'Did not finish', 'Did not finish') }, // user never completed the race nor pressed stop until the race time ended
  100: { ...labels('Unknown', 'Unknown', 'Unknown'), isActive: true }, // missing points during replay
  [undefined]: { ...labels('Unknown', 'Unknown', 'Unknown') },
};

function labels(beforeStart, live, afterEnd) {
  return {
    labelBeforeStart: beforeStart,
    labelLive: live,
    labelAfterEnd: afterEnd,
  };
}
