import moment from 'moment';

export const RACE_STATUS_STARTING = 'starting';
export const RACE_STATUS_LIVE = 'live';
export const RACE_STATUS_ENDED = 'ended';
export const RACE_STATUS_UNKNOWN = 'unknown';

export function getRaceStatus(race) {
  if (!race) {
    return RACE_STATUS_UNKNOWN;
  }

  const currentTime = moment();
  const startTime = moment.unix(race.startTime);
  const endTime = moment.unix(race.endTime);

  if (currentTime.isBefore(startTime)) {
    return RACE_STATUS_STARTING;
  } else if (currentTime.isBefore(endTime)) {
    return RACE_STATUS_LIVE;
  } else {
    return RACE_STATUS_ENDED;
  }
}
