import React, {useContext, useEffect, useRef, useState} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {Context} from "context/Context";
import "./ElevationGraph.scss";

ChartJS.register(...registerables);

const LINE_OPTIONS = {
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            }
        },
    },
    plugins: {
        legend: {
            fullSize: false,
            labels: {
                filter: (label) => label.text !== "Elevation"
            },
            display: false
        },
        tooltip: {
            enabled: false,
        },
    },
    elements: {
        point: {radius: 0, hoverRadius: 0},
        line: {tension: 0}
    },
    responsive: true,
    maintainAspectRatio: false
};

const REFRESH_TIMEOUT = 5000;

const ElevationGraph = ({labels, data, precision}) => {
    const {
        state: {playback, participantPoints: points, selectedParticipants, activeTrail},
    } = useContext(Context);
    const chartRef = useRef(null);
    const [chartLabels, setChartLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [refreshTimeout, setRefreshTimeout] = useState(REFRESH_TIMEOUT);
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        const chart = chartRef.current;
        if (!chart) {
            return;
        }
        setChartLabels(labels.map(([lat, long]) => JSON.stringify([lat / 10 ** precision.coord, long / 10 ** precision.coord])));
        setDatasets([{
            label: "Elevation",
            fill: "origin",
            backgroundColor: createGradient(chart.ctx, chart.chartArea),
            borderColor: "#651a98",
            borderWidth: 1,
            order: 1,
            data: data.map((val) => val / 10 ** precision.elevation)
        }]);
    }, [chartRef, labels, data, precision]);

    useEffect(() => {
        setRefresh(true);
    }, [selectedParticipants]);

    useEffect(() => {
        if (!isFirstRender && (playback.status === 'PAUSED' || playback.status === 'STOPPED')) {
            return;
        }
        if (isFirstRender) {
            setIsFirstRender(false);
        }
        const intervalId = setInterval(() => setRefresh(true), refreshTimeout);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTimeout, playback.status]);

    useEffect(() => {
        const newTimeout = REFRESH_TIMEOUT / playback.speed;
        if (refreshTimeout !== newTimeout) {
            setRefreshTimeout(newTimeout < 1000 ? 1000 : newTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playback]);

    useEffect(() => {
        if (!refresh) {
            return;
        }
        if (!points.length || !selectedParticipants.length || !chartLabels.length || !datasets.length) {
            return;
        }
        const selectedParticipantsPoints = points.filter((val) => selectedParticipants.includes(val.id));
        const elevationDataset = datasets[0];

        const updatedDatasets = selectedParticipantsPoints.map((participant) => {
            const {hexValue, userName, stats} = participant;
            const theMostRecentParticipantPosition = stats.currentDistanceM;
            if (!theMostRecentParticipantPosition) {
                return null;
            }

            const {
                value: closestToParticipantCoordinate,
                index
            } = getClosestPointToParticipant(theMostRecentParticipantPosition);

            return {
                label: userName,
                backgroundColor: hexValue,
                fillColor: hexValue,
                borderColor: '#CDC5D2',
                borderWidth: 1,
                order: 0,
                type: 'bubble',
                data: [{
                    y: elevationDataset.data[index],
                    x: closestToParticipantCoordinate,
                    r: 7
                }]
            };
        });
        setDatasets([elevationDataset, ...updatedDatasets].filter((v) => !!v));
        setRefresh(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, selectedParticipants, points]);

    const createGradient = (ctx, area) => {
        const gradient = ctx.createLinearGradient(0, area.top, 0, area.bottom);
        gradient.addColorStop(0, '#EDE0F5');
        gradient.addColorStop(1, 'rgba(237, 224, 245, 0)');
        return gradient;
    };

    const getClosestPointToParticipant = (currentPositionM) => {
        const {targetDistance} = activeTrail;
        const labelsLength = chartLabels.length;
        if (!targetDistance || !currentPositionM) {
            return chartLabels[0];
        }
        const index = Math.floor(currentPositionM * labelsLength / targetDistance);
        return {value: chartLabels[index], index};
    };

    return (
        <div className="chart-container">
            <Line data={{labels: chartLabels, datasets}} options={LINE_OPTIONS} type="line" className="elevation-graph"
                  ref={chartRef}/>
        </div>
    );
}

export default ElevationGraph;
