import React from 'react';
import { Link } from 'react-router-dom';
import { PlaybackControls } from "components/Replay/PlaybackControls/PlaybackControls";
import { RaceStats } from "components/RaceStats/RaceStats";

import logo from 'assets/png/logo_purple.png';
import racelog from 'assets/png/racelog_purple.png';
import './Logo.scss';

export function Logo({ className, replay, activeTrail, activeRace }) {
  return (
    <div className={`logo-root ${className || ''}`}>
      <Link to="/race" className="logo-container">
        <img className="logo" src={logo} alt="RaceLog" />
        <img className="racelog" src={racelog} alt="RaceLog" />
      </Link>
      {replay && <PlaybackControls className='playback'/>}
      {activeTrail && activeRace && <RaceStats/>}
    </div>
  );
}
