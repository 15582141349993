import React, {forwardRef, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import moment from 'moment';
import {IconButton} from 'components/IconButton/IconButton';
import {PlaybackControls} from "components/Replay/PlaybackControls/PlaybackControls";
import {RaceStats} from "components/RaceStats/RaceStats";
import Checkbox from "components/Checkbox/Checkbox";
import {Context, TOGGLE_PARTICIPANT_LIST, TOGGLE_PARTICIPANTS} from 'context/Context';
import {PARTICIPANT_STATUSES} from 'helpers/statusHelper';
import {formatDuration} from 'helpers/dateHelper';
import {getAgeGroups} from "service/api";
import elevationIcon from 'assets/png/icon_elevation_dark.png';
import speedIcon from 'assets/png/icon_speed_dark.png';
import distanceIcon from 'assets/png/icon_distance_dark.png';
import iconImg from 'assets/svg/icon.svg';
import finishIcon from 'assets/svg/finish_icon.svg';
import arrowUpIcon from 'assets/svg/up-arrow_icon.svg';
import arrowDownIcon from 'assets/svg/down-arrow_icon.svg';
import './ParticipantList.scss';

const participantQueryParam = 'participant';
const GENDERS = {
    "M": "Male",
    "F": "Female",
    "Ag": "Agender"
};

export const ParticipantList = forwardRef(function ParticipantList({className, getStatusLabel}, ref) {
    const {
        state: {activeTrailId, participants, selectedParticipants, participantListCollapsed, participantPoints, replay},
        dispatch,
    } = useContext(Context);
    const history = useHistory();
    const location = useLocation();
    const queryParams = useQuery();
    const participantsParam = useMemo(() => queryParams.getAll(participantQueryParam) || [], [queryParams]);
    const [ageGroups, setAgeGroups] = useState([]);
    const [activeAgeGroup, setActiveAgeGroup] = useState({
        name: "ALL",
        gender: "ALL",
        ageFrom: 1,
        ageTo: 99
    });
    const [availableGenders, setAvailableGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState("ALL");
    const [firstRender, setFirstRender] = useState(true);
    const isLoading = false;

    useEffect(() => {
        if (activeTrailId && firstRender) {
            setFirstRender(false);
            getAgeGroups(activeTrailId).then((data) => {
                const gendersFromData = data.map(({gender}) => ({
                    label: GENDERS[gender],
                    abbr: gender
                }));
                setAvailableGenders([{
                    label: "ALL",
                    abbr: "ALL"
                }, ...new Map(gendersFromData.map((item) => [item.abbr, item])).values()]);
                setAgeGroups([
                    {
                        name: "ALL",
                        gender: "ALL",
                        ageFrom: 1,
                        ageTo: 99
                    },
                    ...data.map((ageGroup) => ({
                        name: ageGroup.name,
                        gender: ageGroup.gender,
                        ageFrom: ageGroup.ageFrom,
                        ageTo: ageGroup.ageTo
                    }))]);
            }).catch((e) => console.error(e));
        }
    }, [activeTrailId, firstRender]);

    useEffect(() => {
        if (participants.length > 0 && participantsParam.length !== selectedParticipants.length) {
            dispatch({
                type: TOGGLE_PARTICIPANTS,
                payload: {selectedParticipants: participants.filter((p) => participantsParam.includes(String(p.id)))}
            });
        }
    }, [participantsParam, selectedParticipants, dispatch, participants]);

    const onGenderChange = (event) => {
        setSelectedGender(event.target.value);
    }

    const onAgeGroupSelect = (ageGroup) => setActiveAgeGroup({
        name: ageGroup.name,
        gender: ageGroup.gender,
        ageFrom: ageGroup.ageFrom,
        ageTo: ageGroup.ageTo
    });

    const onParticipantSelection = (participantId) => {
        const participantIdString = String(participantId);
        let selectionParam = participantsParam.includes(participantIdString)
            ? participantsParam.filter((p) => p !== participantIdString)
            : [...participantsParam, participantIdString];
        if (selectionParam.length > 10) {
            selectionParam = selectionParam.slice(0, 10);
        }
        history.push(`${location.pathname}?${selectionParam.map((p) => `${participantQueryParam}=${p}`).join('&')}`);
    }

    return (
        <div className={`participant-list${participantListCollapsed ? ' collapsed' : ''} ${className || ''}`} ref={ref}>
            <div className='list-header'>
                <div className="control-panel">
                    <div className="controls-block">
                        {replay && <PlaybackControls className='playback'/>}
                        <IconButton className='menu-button'
                                    icon={participantListCollapsed ? arrowUpIcon : arrowDownIcon}
                                    onClick={() => dispatch({type: TOGGLE_PARTICIPANT_LIST})}/>
                    </div>
                    {participantListCollapsed && (<RaceStats/>)}
                </div>
                <div className="age-groups-container">
                    {!!participants.length && ageGroups.length > 1 && availableGenders.length > 1 && (
                        <div className="gender-options">
                            {availableGenders.map((val) => (
                                <label className="label" key={val.label}>
                                    <input type="radio" name="gender" value={val.abbr}
                                           checked={selectedGender === val.abbr}
                                           onChange={onGenderChange}/>
                                    <span>{val.label}</span>
                                </label>
                            ))}
                        </div>
                    )}
                    {!!participants.length && ageGroups.length > 1 && (
                        <div className="age-groups-list">
                            {ageGroups.map((val) => (
                                <button key={`${val.name}${val.ageTo}${val.ageFrom}`}
                                        className={`age-group${activeAgeGroup.name === val.name ? " active" : ""}`}
                                        onClick={() => onAgeGroupSelect(val)}>
                                    {`${val.name} (${val.ageFrom} - ${val.ageTo})`}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {participants.length === 0 && !isLoading ? (
                <>
                    <img className='liveWatch-placeholderImage' src={iconImg} alt=''/>
                    <div className='liveWatch-placeholderText'>No items in this list</div>
                </>
            ) : (
                <div className={`participants${!availableGenders.length ? " no-age-group" : ""}`}>
                    {participants.map((participant, index) => {
                        const participantId = participant.id;
                        const participantGender = participant.gender;
                        const participantAge = participant.age;

                        const stats = participantPoints.find((p) => p.id === participantId)?.stats;
                        const status = PARTICIPANT_STATUSES[stats?.status || participant.status];
                        const statusLabel = getStatusLabel(status);
                        const finishDuration =
                            status.isFinished && participant.startTime && participant.finishTime
                                ? formatDuration(moment.duration(moment(participant.finishTime).diff(moment(participant.startTime))))
                                : null;

                        const isValidGender = selectedGender === "ALL" || selectedGender === participantGender;
                        const isValidAgeGroup = (activeAgeGroup.gender === "ALL") || (activeAgeGroup.gender === participantGender
                            && activeAgeGroup.ageFrom <= participantAge
                            && activeAgeGroup.ageTo >= participantAge);

                        if (isValidAgeGroup && isValidGender) {
                            return (
                                <div
                                    key={participantId}
                                    className="participant"
                                >
                                    <div className="participant-data">
                                        <div className="checkbox-column">
                                            <Checkbox onChange={() => onParticipantSelection(participantId)}
                                                      checked={selectedParticipants.includes(participantId)}/>
                                        </div>
                                        <div className="participant-column">
                                            <div className='participant-info'>
                                                <div className='position'
                                                     style={{background: participant.hexValue}}>{index + 1}</div>
                                                <div className='name'>{participant.userName}</div>
                                            </div>
                                            <div className='participant-statistics'>
                                                <ParticipantStatistic className='distance'
                                                                      label={<img src={distanceIcon} alt="" width={24}
                                                                                  height={24}/>}>
                                                    {formatDistance(stats?.currentDistanceM)}
                                                </ParticipantStatistic>
                                                <ParticipantStatistic className='elevation'
                                                                      label={<img src={elevationIcon} alt="" width={10}
                                                                                  height={10}/>}>
                                                    {formatElevation(stats?.currentElevationM)}
                                                </ParticipantStatistic>
                                                {!status.isFinished && (
                                                    <ParticipantStatistic className='speed'
                                                                          label={<img src={speedIcon} alt="" width={10}
                                                                                      height={10}/>}>
                                                        {formatSpeed(stats?.currentSpeed)}
                                                    </ParticipantStatistic>
                                                )}
                                                {status.isFinished && (
                                                    <ParticipantStatistic className='speed'
                                                                          label={<img src={speedIcon} alt="" width={10}
                                                                                      height={10}/>}>
                                                        {formatSpeed(stats?.avgSpeed)}
                                                    </ParticipantStatistic>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`status${status.isFinished ? ' finished' : ''}`}>
                                            {status.isFinished && (
                                                <img src={finishIcon} alt="" width={14} height={11}
                                                     className="icon"/>
                                            )}
                                            <div className="status-details">
                                                {statusLabel + ' ' || ''}
                                                {finishDuration && (
                                                    <span className='finish-duration'>
                                                  <span className='duration'>{finishDuration}</span>
                                                </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            )}
        </div>
    );
});

function ParticipantStatistic({label, className, children}) {
    return (
        <div className={`participant-statistic ${className || ''}`}>
            <label className='label'>{label}</label>
            <div className='value'>{children}</div>
        </div>
    );
}

function formatDistance(distanceMeters) {
    if (distanceMeters == null) {
        return '- km';
    }

    if (distanceMeters > 1000) {
        return `${toFixed(distanceMeters / 1000, 2)} km`;
    }

    return `${Math.round(distanceMeters)} m`;
}

function formatElevation(elevation) {
    if (elevation == null) {
        return '- m';
    }

    return `${elevation} m`;
}

function formatSpeed(speed) {
    if (speed == null) {
        return '- km/h';
    }

    return `${speed} km/h`;
}

function toFixed(num, fixed) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
