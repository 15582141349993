import React from 'react';
import './RaceListHeader.scss';
import logo from 'assets/png/logo_white.png';
import racelog from 'assets/png/racelog_white.png';

export function RaceListHeader() {
  return (
    <div className="race-list-header">
      <div className="logo-container">
        <img className="logo" src={logo} alt="RaceLog"></img>
        <img className="racelog" src={racelog} alt="RaceLog"></img>
      </div>
      <h1 className="title">Race list</h1>
    </div>
  );
}
