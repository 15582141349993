import { ParticipantList } from 'components/ParticipantList/ParticipantList';
import moment from 'moment';
import React, { useEffect, useContext, useCallback, forwardRef } from 'react';
import { getParticipants, getPoints } from 'service/api';
import { Context, TRAIL_LOADED, POINTS_LOADED, PARTICIPANTS_LOADED, PARTICIPANTS_UNLOADED } from 'context/Context';
import { useParams } from 'react-router-dom';
import { RACE_STATUS_STARTING, RACE_STATUS_LIVE, RACE_STATUS_ENDED, getRaceStatus } from 'helpers/raceHelper';

export const LiveWatch = forwardRef(function LiveWatch({ className }, ref) {
    const {
        state: { participants, activeRace },
        dispatch,
    } = useContext(Context);

    const { raceId, trailId } = useParams();

    const loadParticipants = useCallback(() => {
        getParticipants(trailId).then((participants) => {
            if (participants.status === 'error') {
                return;
            }

            dispatch({ type: PARTICIPANTS_LOADED, payload: { participants } });
        });
    }, [trailId, dispatch]);

    useEffect(() => {
        dispatch({
            type: TRAIL_LOADED,
            payload: { raceId, trailId, liveWatch: true },
        });

        loadParticipants();

        return () => {
            dispatch({ type: PARTICIPANTS_UNLOADED });
        };
    }, [loadParticipants, dispatch, raceId, trailId]);

    useEffect(() => {
        if (!activeRace) {
            return;
        }

        const { liveWatchRefreshInterval } = activeRace;
        const refreshInterval = liveWatchRefreshInterval * 1000;

        const timeoutId = setTimeout(() => {
            loadParticipants();
        }, refreshInterval);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [activeRace, loadParticipants, participants]);

    useEffect(() => {
        if (!activeRace || participants.length === 0) {
            return;
        }

        const participantIds = participants.map((p) => p.id);

        getPoints(trailId, moment().utc().format('YYYY-M-D HH:mm:ss'), participantIds, activeRace.tailLength, activeRace.apiVersion).then((points) => {
            dispatch({ type: POINTS_LOADED, payload: { points } });
        });
    }, [activeRace, participants, trailId, dispatch]);

    const raceStatus = getRaceStatus(activeRace);

    return <ParticipantList className={className} getStatusLabel={(s) => getStatusLabelByRaceStatus(s, raceStatus)} ref={ref}/>;
});

function getStatusLabelByRaceStatus(status, raceStatus) {
    switch (raceStatus) {
        case RACE_STATUS_STARTING:
            return status.labelBeforeStart;
        case RACE_STATUS_LIVE:
            return status.labelLive;
        case RACE_STATUS_ENDED:
            return status.labelAfterEnd;
        default:
            return '';
    }
}
