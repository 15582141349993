import React, { useState, useEffect, useContext } from 'react';
import { formatDuration } from 'helpers/dateHelper';
import moment from 'moment';
import './RaceStats.scss';
import { LinkButton } from 'components/Button/Button';
import { getTrailReplayUrl } from 'url.js';
import { Context } from 'context/Context';
import {
  getRaceStatus,
  RACE_STATUS_STARTING,
  RACE_STATUS_LIVE,
  RACE_STATUS_ENDED,
} from 'helpers/raceHelper';

export function RaceStats() {
  const {
    state: { activeRace, activeTrail, replay, playback },
  } = useContext(Context);
  const [currentTime, setCurrentTime] = useState(moment());

  const startTime = moment.unix(activeRace.startTime);
  const endTime = moment.unix(activeRace.endTime);

  const raceStatus = getRaceStatus(activeRace);
  const isStarting = raceStatus === RACE_STATUS_STARTING;

  useEffect(() => {
    const timout = moment(currentTime)
      .add(1, 'second')
      .startOf('second')
      .diff(currentTime);
    const timeoutId = setTimeout(() => {
      setCurrentTime(moment());
    }, timout);

    return () => clearTimeout(timeoutId);
  }, [currentTime]);

  return (
    <div className="race-data">
      <Stat
        label="Race date"
        className="race-stats-duration"
      >
        {formatDateTime(startTime)}
      </Stat>
      {raceStatus === RACE_STATUS_LIVE && !replay && (
        <>
          <Stat
            label="Race duration"
            className="race-stats-duration"
          >
            {formatDuration(moment.duration(currentTime.diff(startTime)))}
          </Stat>
          <Stat
            label="Race ends at"
            valueClassName="time"
            className="race-stats-time"
          >
            {formatDateTime(endTime)}
          </Stat>
          <br />
          <div className="race-stats-live">Live</div>
        </>
      )}
      {isStarting && (
        <>
          <Stat
            label="Race starting in"
            className="race-stats-duration"
          >
            {formatDuration(moment.duration(startTime.diff(currentTime)))}
          </Stat>
          <Stat
            label="Race start"
            valueClassName="time"
            className="race-stats-time"
          >
            {formatDateTime(startTime)}
          </Stat>
        </>
      )}
      {replay && !isStarting && (
        <>
          <Stat
            label="Race duration"
            className="race-stats-duration"
          >
            {formatDuration(moment.duration(playback.duration))}
          </Stat>
          <Stat
            label="Race ended at"
            valueClassName="time"
            className="race-stats-time"
          >
            {formatDateTime(endTime)}
          </Stat>
        </>
      )}
      {raceStatus === RACE_STATUS_ENDED && !replay && (
        <>
          <Stat
            label="Race ended on"
            valueClassName="time"
            className="race-stats-time ended"
          >
            {formatDateTime(endTime)}
          </Stat>
          {!replay && (
            <LinkButton
              className="race-stats-replay-btn"
              to={getTrailReplayUrl(activeTrail.id)}
            >
              Replay
            </LinkButton>
          )}
        </>
      )}
    </div>
  );
}

function Stat({ label, children, valueClassName, className }) {
  return (
    <div className={`race-stat ${className || ''}`}>
      <label className="race-stats-label">{label}</label>
      <div className={`race-stats-value ${valueClassName || ''}`}>
        {children}
      </div>
    </div>
  );
}

function formatDateTime(momnt) {
  return (
    <>
      <span>{momnt.format('DD.MM.YYYY')}</span>
    </>
  );
}
