import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { parse } from 'qs';
import IconBack from 'components/IconBack/IconBack';
import { VirtualTypeLabel } from 'components/VirtualTypeLabel/VirtualTypeLabel';
import { Context } from 'context/Context';
import { formatMoment } from 'helpers/dateHelper';
import { URL_RACE_LIST } from 'url.js';
import './TrailListHeader.scss';

export function TrailListHeader() {
    const {
        state: { activeRace },
    } = useContext(Context);

    const { search } = useLocation();
    const { hideNavigation } = parse(search, { ignoreQueryPrefix: true });

    let raceTime = null;
    if (activeRace) {
        raceTime = formatMoment(moment.unix(activeRace.startTime));

        if (activeRace.virtualType === 2) {
            raceTime += ' - ' + formatMoment(moment.unix(activeRace.endTime));
        }
    }

    return (
        <div className='trail-list-header'>
            <div className='back-link'>
                <div className="trail-name">
                    {!hideNavigation && (
                        <Link to={URL_RACE_LIST} className="link">
                          <IconBack className='icon-back' />
                        </Link>
                    )}
                    {activeRace?.raceName && (<h1 className='race-name'>{activeRace.raceName}</h1>)}
                </div>
                {activeRace?.virtualType > 0 && <VirtualTypeLabel type={activeRace.virtualType} className='virtual-trail'/>}
            </div>
            <div className='title'>
                <span>Trail list</span>
            </div>
            {raceTime && (<div className='start-time'>{raceTime}</div>)}
        </div>
    );
}
