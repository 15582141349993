const getBrightness = (r, g, b) => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const isDark = hexColor => {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);
  return getBrightness(r, g, b) < 123;
};
