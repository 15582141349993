import React from 'react';
import './IconButton.scss';

export function IconButton({icon, disabled, onClick, className}) {
    return (
        <button onClick={onClick} className={`icon-button ${className || ''}${disabled ? " disabled" : ""}`}
                disabled={disabled}>
            <img alt="" src={icon} className="followParticipantsControl-img"/>
        </button>
    );
}
