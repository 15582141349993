import { Context, RACE_LOADED } from 'context/Context';
import React, { useContext, useEffect, forwardRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getTrailDetailUrl, getTrailReplayUrl } from 'url.js';
import './TrailList.scss';
import { LinkButton } from 'components/Button/Button';
import moment from 'moment';

export const TrailList = forwardRef(function TrailList({ className }, ref) {
    const { raceId } = useParams();
    const {
        state: { activeRace },
        dispatch,
    } = useContext(Context);

    const { search } = useLocation();

    useEffect(() => {
        dispatch({ type: RACE_LOADED, payload: { raceId } });
    }, [raceId, dispatch]);

    const trailList = activeRace ? activeRace.trails : [];

    const currentTime = moment();
    const startTime = activeRace ? moment.unix(activeRace.startTime) : currentTime;
    const endTime = activeRace ? moment.unix(activeRace.endTime) : currentTime;
    const isStarting = currentTime.isBefore(startTime);
    const isEnded = currentTime.isAfter(endTime);

    return (
        <div className={`trail-list ${className || ''}`} ref={ref}>
            <div className='content-header'>
                {activeRace?.virtualType > 0 ? (
                    <p className='virtual-rece-header'>
                        {activeRace?.virtualType === 1
                            ? 'This is virtual race where everyone runs in their favorite location, at the same time. The target is to run the length of the chosen trail and/or minium elevation gain.'
                            : 'Everyone has to run on the same trail but at different times (usually within a window of a couple of days).'}
                    </p>
                ) : (
                    activeRace && (
                        <>
                            <div className='race-location'>
                                <div className='location-text'>{activeRace?.locationName || '\u00A0'}</div>
                            </div>
                            <div className='location-icon'>{'\u21B1'}</div>
                        </>
                    )
                )}
            </div>
            <div className='trails'>
                {trailList.map((trail) => (
                    <div key={trail.id} className='trail'>
                        <div className='trail-info'>
                            <div className='trail-info-content'>
                                <h2 className='trail-name'>{trail.trailName}</h2>
                                <div className='trail-detail'>Length: {trail.trailLength}</div>
                                {trail.trailAltDelta && <div className='trail-detail'>Elevation: {trail.trailAltDelta}</div>}
                            </div>
                        </div>
                        <div className='actions'>
                            {!isEnded && <LinkButton to={getTrailDetailUrl(trail.id) + search}>Watch</LinkButton>}
                            {!isStarting && (
                                <LinkButton className='action-replay' bgClassName='action-replay-bg' to={getTrailReplayUrl(trail.id) + search}>
                                    Replay
                                </LinkButton>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});
