import React from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';

export function LinkButton({ to, className, bgClassName, children }) {
  return (
    <Link className={`button ${className || ''}`} to={to}>
      <BG fillCassName={bgClassName}></BG>
      <span>{children}</span>
      {children}
    </Link>
  );
}

function BG({ fillCassName }) {
  return (
    <svg
      className="button-bg"
      width="92px"
      height="34px"
      viewBox="0 0 92 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Mobile-Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          className={`fill ${fillCassName}`}
          transform="translate(-45.000000, -474.000000)"
        >
          <path
            d="M58.2717863,474 L123.640637,474 C126.07328,474 128.331491,475.262958 129.604921,477.335669 L135.748712,487.335669 C137.129667,489.583394 137.129667,492.416606 135.748712,494.664331 L129.604921,504.664331 C128.331491,506.737042 126.07328,508 123.640637,508 L58.4426592,508 C55.9650184,508 53.671957,506.690264 52.4132571,504.556165 L46.1875896,494.000678 C44.8459581,491.725971 44.8987142,488.889816 46.3240134,486.666577 L52.378808,477.222063 C53.6659804,475.21428 55.8868318,474 58.2717863,474 Z"
            id="button"
          ></path>
        </g>
      </g>
    </svg>
  );
}
