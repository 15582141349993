import React from 'react';
import './IconBack.scss';

const IconBack = ({ className = '' }) => (
  <svg
    className={`svg-icon ${className || ''}`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="assets"
        transform="translate(-291.000000, -41.000000)"
        fill="#ffffff"
      >
        <g id="close" transform="translate(295.000000, 45.000000)">
          <polygon
            id="Shape"
            points="16 7 3.8 7 9.4 1.4 8 0 0 8 8 16 9.4 14.6 3.8 9 16 9"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconBack;
