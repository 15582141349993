import React, {useContext, useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { VirtualTypeLabel } from 'components/VirtualTypeLabel/VirtualTypeLabel';
import DownloadButton from "components/DownloadButton/DownloadButton";
import IconBack from 'components/IconBack/IconBack';
import { Context } from 'context/Context';
import { getGpxDownloadLink } from "service/api";
import { getRaceDetailUrl, URL_RACE_LIST } from 'url.js';
import distanceIcon from 'assets/svg/distance_icon.svg';
import elevationIcon from 'assets/svg/elevation_icon.svg';
import './TrailDetailHeader.scss';

export function TrailDetailHeader() {
    const {
        state: { activeRaceId, activeRace, activeTrail, activeTrailId },
    } = useContext(Context);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [firstRender, setFirstRender] = useState(true);
    const { search } = useLocation();
    const { hideNavigation } = parse(search, { ignoreQueryPrefix: true });

    const backUrl = activeRaceId ? getRaceDetailUrl(activeRaceId) : URL_RACE_LIST;

    useEffect(() => {
        if (activeTrailId && firstRender) {
            setFirstRender(false);
            getGpxDownloadLink(activeTrailId).then((url) => setDownloadUrl(url));
        }
    },[activeTrailId, firstRender]);

    const onDownloadClick = () => {
        window.open(downloadUrl, "_blank");
    };

    return (
        <div className={`trail-detail-header ${hideNavigation ? 'hide-nav' : ''}`}>
              <div className='back-link'>
                  <div className="trail-name">
                      {!hideNavigation && (
                          <Link to={backUrl} className="link">
                              <IconBack className='icon-back' />
                          </Link>
                      )}
                      {activeTrail?.trailName && (<div className='trail-name'>{activeTrail.trailName}</div>)}
                  </div>
                  {activeRace?.virtualType > 0 && (<VirtualTypeLabel type={activeRace.virtualType} className='virtual-trail'/>)}
              </div>
            <br />
            {activeRace?.raceName && (
                <div className='race-name'>
                    <div className="name-wrapper">
                        <h1>{activeRace.raceName}</h1>
                    </div>
                    <div className="download-btn-wrapper">
                        {!!downloadUrl && (
                            <DownloadButton onClick={onDownloadClick}>
                                GPX file
                            </DownloadButton>
                        )}
                    </div>
                </div>
            )}
            {!activeRace?.virtualType && (
                <div className='trail-length'>
                  <img src={distanceIcon} alt="" height={24} width={24} className="icon" />
                  <label className='label'>Target distance:</label>
                    <span>{activeTrail?.trailLength || ''}</span>
                </div>
            )}
            {activeRace?.virtualType > 0 && (
                <>
                    {!!activeTrail?.targetDistance && (
                        <div className='trail-length'>
                            <img src={distanceIcon} alt="" height={24} width={24} className="icon" />
                            <label className='label'>Target distance:</label>
                            <span>{(activeTrail.targetDistance / 1000).toFixed(1)}km</span>
                        </div>
                    )}
                    {!!activeTrail?.targetElevation && (
                        <div className='trail-length'>
                          <img src={elevationIcon} alt="" height={20} width={20} className="icon" />
                          <label className='label'>Target elevation:</label>
                            <span>{activeTrail.targetElevation}m</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
