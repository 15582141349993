import React from 'react';

export function ParticipantMarker({ fill = '#000' }) {
  return (
    <svg
      width="45px"
      height="45px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Webapp-Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Race-details---normal-race"
          transform="translate(-1072.000000, -636.000000)"
          fill={fill}
        >
          <g id="Group-3" transform="translate(960.000000, 370.000000)">
            <g
              id="Group-10-Copy-3"
              transform="translate(112.000000, 266.000000)"
            >
              <path
                d="M18.5985939,3.80140606 L3.80140606,18.5985939 C1.70194746,20.6980525 1.70194746,24.1019475 3.80140606,26.2014061 L18.5985939,40.9985939 C20.6980525,43.0980525 24.1019475,43.0980525 26.2014061,40.9985939 L40.9985939,26.2014061 C43.0980525,24.1019475 43.0980525,20.6980525 40.9985939,18.5985939 L26.2014061,3.80140606 C24.1019475,1.70194746 20.6980525,1.70194746 18.5985939,3.80140606 Z"
                id="Path-2-Copy-2"
                opacity="0.200000003"
              ></path>
              <path
                d="M19.3588752,12.0011248 L12.0011248,19.3588752 C10.321558,21.038442 10.321558,23.761558 12.0011248,25.4411248 L19.3588752,32.7988752 C21.038442,34.478442 23.761558,34.478442 25.4411248,32.7988752 L32.7988752,25.4411248 C34.478442,23.761558 34.478442,21.038442 32.7988752,19.3588752 L25.4411248,12.0011248 C23.761558,10.321558 21.038442,10.321558 19.3588752,12.0011248 Z"
                id="Path-2-Copy"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
