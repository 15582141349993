import {stringify} from 'qs';
import moment from 'moment';

var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
};

const apiV1Url = process.env.REACT_APP_API_V1_BASE_URL;
const apiV2Url = process.env.REACT_APP_API_V2_BASE_URL;
const apiV3Url = process.env.REACT_APP_API_V3_BASE_URL;

function getRaceList(locationLat, locationLong, radius) {
    const queryString =
        locationLat && locationLong
            ? stringify(
                {
                    locationLat,
                    locationLong,
                    radius,
                    timeIntervalFrom: moment(1).unix(),
                    timeIntervalTo: moment().add(1, 'year').unix(),
                },
                {encode: false}
            )
            : null;

    return fetch(`${apiV1Url}/get-races-new${queryString ? '?' + queryString : ''}`, {
        headers: headers,
    })
        .then((res) => res.json())
        .catch((err) => err);
}

function getPoints(trailId, timestamp, participantIds, numberOfCoordinates, apiVersion) {
    const url = `${apiVersion === 'v3' ? apiV3Url : apiV2Url}/points`;
    const queryString = stringify({trailId, timestamp, participantIds, numberOfCoordinates}, {
        encode: false,
        arrayFormat: 'brackets'
    });

    return fetch(url + (queryString ? `?${queryString}` : ''), {
        headers: headers,
    }).then((res) => {
        if (res.status >= 400) {
            return [];
        }

        return res.json();
    });
}

function getPointsReplay(trailId, participantIds, apiVersion) {
    const url = `${apiVersion === 'v3' ? apiV3Url : apiV2Url}/points/replay`;
    const queryString = stringify({trailId, participantIds}, {encode: false, arrayFormat: 'brackets'});

    return fetch(url + (queryString ? `?${queryString}` : ''), {
        headers: headers,
    }).then((res) => {
        if (res.status >= 400) {
            return [];
        }

        return res.json();
    });
}

const getParticipants = (trailId) => {
    const queryString = stringify({trailId}, {encode: false});
    return fetch(apiV2Url + `/participants?${queryString}`, {
        headers: headers,
    }).then((res) => {
        if (res.status >= 400) {
            return [];
        }

        return res.json();
    });
};

const getGpxDownloadLink = async (activeTrailId) => {
    if (activeTrailId) {
        const response = await fetch(`${apiV3Url}/gpx-url?trailId=${activeTrailId}`, {
            headers
        });
        const {success, data, error} = await response.json();

        if (!success) {
            console.log(error);
            return null
        }
        return data;
    }
}

const getElevationData = async (activeTrailId) => {
    if (activeTrailId) {
        const response = await fetch(`${apiV3Url}/elevation?trailId=${activeTrailId}`, {
            headers
        });
        const {success, data, error} = await response.json();

        if (!success) {
            console.log(error);
            return null
        }
        return data;
    }
}

const getOverlayData = async (activeTrailId) => {
    if (activeTrailId) {
        const response = await fetch(`${apiV3Url}/get-overlay?trailId=${activeTrailId}`, {
            headers
        });
        const {success, data, error} = await response.json();

        if (!success) {
            console.log(error);
            return null
        }
        return data;
    }
}

const getAgeGroups = async (activeTrailId) => {
    if (activeTrailId) {
        const response = await fetch(`${apiV3Url}/age-groups?trailId=${activeTrailId}`, {
            headers
        })
        const {success, data, error} = await response.json();

        if (!success) {
            console.log(error);
            return null
        }
        return data;
    }
}

export {
    getRaceList,
    getPoints,
    getPointsReplay,
    getParticipants,
    getGpxDownloadLink,
    getElevationData,
    getOverlayData,
    getAgeGroups
};
